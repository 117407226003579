
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_config.hat_manage') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('warehouse_config.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.from_date')"
                        label-for="from_date"
                        >
                        <b-form-input
                            id="datepicker"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.from_date')"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.to_date')"
                        label-for="to_date"
                        >
                        <b-form-input
                            id="datepicker"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.to_date')"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_ginner_grower.hat_manage') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(zone_name)="data">
                                            {{ data.item.zone_name }}
                                        </template>
                                        <template v-slot:cell(zone_name_bn)="data">
                                            {{ data.item.zone_name_bn }}
                                        </template>
                                         <template v-slot:cell(hatt_date)="data">
                                            {{ data.item.hatt_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.closed')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.not_closed')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Sales Center Manage Modify" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Click to Close" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Open" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { hatManageList, hatManageStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import { getCottonMarketUserInfo, getUnitAssignedParams } from '@/Utils/data-filtering-by-priviledge'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        from_date: '',
        to_date: '',
        region_id: 0,
        zone_id: 0,
        unit_id: 0,
        district_id: 0,
        upazila_id: 0
      }
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    cottonMarketUserInfo () {
        return getCottonMarketUserInfo()
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.sort((a, b) => b.sorting_order - a.sorting_order)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('cotton_ginner_grower.hat_manage_entry') : this.$t('cotton_ginner_grower.hat_manage_modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('cotton_ginner_grower.region'), class: 'text-center' },
          { label: this.$t('org_pro_district.district'), class: 'text-center' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
          { label: this.$t('cotton_ginner_grower.unit_name'), class: 'text-center' },
          { label: this.$t('cotton_ginner_grower.hat_name'), class: 'text-center' },
          { label: this.$t('globalTrans.date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'region_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazila_name_bn' },
          { key: 'unit_name_bn' },
          { key: 'hat_name_bn' },
          { key: 'hatt_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'region_name' },
          { key: 'district_name' },
          { key: 'upazila_name' },
          { key: 'unit_name' },
          { key: 'hat_name' },
          { key: 'hatt_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    cottonMarketUserInfo: function () {
      this.setUserData()
    }
  },
  created () {
    this.setUserData()
    this.loadData()
  },
  methods: {
    async searchData () {
      await this.loadData()
    },
    remove (item) {
      this.changeStatus(agriMarketingServiceBaseUrl, hatManageStatus, item)
    },
    async loadData () {
      const unitAssignedParams = getUnitAssignedParams()
      const params = Object.assign({}, this.search, unitAssignedParams, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(agriMarketingServiceBaseUrl, hatManageList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelatinalData (data) {
      const regionList = this.$store.state.agriMarketing.commonObj.regionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unitList = this.$store.state.agriMarketing.commonObj.unitList
      const hatListData = this.$store.state.agriMarketing.commonObj.hatList

      const listData = data.map(item => {
        const regionObject = regionList.find(region => region.value === item.region_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazila => upazila.value === item.upazilla_id)
        const unitObject = unitList.find(unit => unit.value === item.unit_id)
        const hatListObject = hatListData.find(hat => hat.value === item.hatt_id)
        const regionData = { region_name: regionObject?.text_en, region_name_bn: regionObject?.text_bn }
        const districtData = { district_name: districtObject?.text_en, district_name_bn: districtObject?.text_bn }
        const upazilaData = { upazila_name: upazilaObject?.text_en, upazila_name_bn: upazilaObject?.text_bn }
        const unitData = { unit_name: unitObject?.text_en, unit_name_bn: unitObject?.text_bn }
        const hatData = { hat_name: hatListObject?.text_en, hat_name_bn: hatListObject?.text_bn }
        return Object.assign({}, item, regionData, districtData, upazilaData, unitData, hatData)
      })
      return listData
    },
    setUserData () {
        if (this.cottonMarketUserInfo.regionList.length === 1) {
            this.search.region_id = this.cottonMarketUserInfo.regionList[0].value
        }
        if (this.cottonMarketUserInfo.zoneList.length === 1) {
            this.search.zone_id = this.cottonMarketUserInfo.zoneList[0].value
        }
        if (this.cottonMarketUserInfo.districtList.length === 1) {
            this.search.district_id = this.cottonMarketUserInfo.districtList[0].value
        }
        if (this.cottonMarketUserInfo.upazilaList.length === 1) {
            this.search.upazila_id = this.cottonMarketUserInfo.upazilaList[0].value
        }
        if (this.cottonMarketUserInfo.unitList.length === 1) {
            this.search.unit_id = this.cottonMarketUserInfo.unitList[0].value
        }
    }
  }
}
</script>
