<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                <b-row>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="fiscal_year_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('warehouse_config.fiscal_year') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.fiscal_year_id"
                                                    :options="fiscalList"
                                                    id="fiscal_year_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Season" vid="seasons_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="seasons_id"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('cotton_ginner_grower.season') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.seasons_id"
                                                    :options="seasonList"
                                                    id="seasons_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="region_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('cotton_ginner_grower.region') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.region_id"
                                                    :options="regionList"
                                                    id="region_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="isOfficeUser"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Zone" vid="zone_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="region_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('cotton_ginner_grower.zone') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.zone_id"
                                                    :options="zoneList"
                                                    id="zone_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="isOfficeUser"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Unit" vid="unit_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="unit_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('cotton_ginner_grower.unit') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.unit_id"
                                                    :options="unitList"
                                                    id="unit_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="isOfficeUser"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('cotton_ginner_grower.division') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.division_id"
                                                    :options="divisionList"
                                                    id="division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('cotton_ginner_grower.district') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.district_id"
                                                    :options="districtList"
                                                    id="district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazilla_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('cotton_ginner_grower.upazilla') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.upazilla_id"
                                                    :options="upazillaList"
                                                    id="upazilla_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Hat Name" vid="hatt_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="hatt_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('cotton_ginner_grower.hat_name') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="hatManage.hatt_id"
                                                    :options="hatList"
                                                    id="hatt_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Hat Date" vid="hatt_date" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="hatt_date"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('cotton_ginner_grower.hat_date')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="datepicker"
                                                v-model="hatManage.hatt_date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Address (En)" vid="address">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="address"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('cotton_ginner_grower.address_en')}}
                                            </template>
                                                <b-form-input
                                                id="address"
                                                v-model="hatManage.address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Address (Bn)" vid="address_bn">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="address_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('cotton_ginner_grower.address_bn')}}
                                            </template>
                                                <b-form-input
                                                id="address_bn"
                                                v-model="hatManage.address_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Remarks (En)" vid="remarks">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="remarks"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('cotton_ginner_grower.remarks_en')}}
                                            </template>
                                                <b-form-textarea
                                                id="remarks"
                                                v-model="hatManage.remarks"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Remarks (Bn)" vid="remarks_bn">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="remarks_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('cotton_ginner_grower.remarks_bn')}}
                                                </template>
                                                <b-form-textarea
                                                    id="remarks_bn"
                                                    v-model="hatManage.remarks_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        &nbsp;
                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { hatManageStore, hatManageUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'
import Common from '@/Utils/common'
import { getCottonMarketUserInfo } from '@/Utils/data-filtering-by-priviledge'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.hattManageData()
            this.hatManage = tmp
        }
        if (this.isOfficeUser) {
          const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
          const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
          if (unit !== undefined) {
            this.hatManage.region_id = unit.region_id
            this.hatManage.zone_id = unit.zone_id
            this.hatManage.unit_id = unit.value
            const district = this.$store.state.commonObj.districtList.find(obj => obj.value === unit.district_id)
            this.hatManage.division_id = district.division_id !== undefined ? district.division_id : 0
            this.hatManage.district_id = unit.district_id
            this.hatManage.upazilla_id = unit.upazilla_id
          }
        }
        this.setUserData()
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            valid: '',
            hatManage: {
                fiscal_year_id: 0,
                seasons_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                hatt_id: 0,
                region_id: 0,
                zone_id: 0,
                unit_id: 0,
                hatt_date: '',
                address: '',
                address_bn: '',
                remarks: '',
                remarks_bn: ''
            },
            errors: [],
            districtList: [],
            upazillaList: [],
            hatList: [],
            zoneList: [],
            unitList: []
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        cottonMarketUserInfo () {
            return getCottonMarketUserInfo()
        },
        seasonList: function () {
            return this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
        },
        regionList: function () {
            // return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
            return this.cottonMarketUserInfo.regionList.filter(item => item.status === 1)
        },
        fiscalList: function () {
            return Common.getFiscalYearList()
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        isOfficeUser () {
        //   return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
          return false
        }
    },
    watch: {
        'hatManage.region_id': function (newVal, oldVal) {
            this.zoneList = this.getZoneList(newVal)
        },
        'hatManage.zone_id': function (newVal, oldVal) {
            this.unitList = this.getUnitList(newVal)
        },
        'hatManage.unit_id': function (newVal, oldVal) {
            this.setHattInfo(newVal)
        },
        'hatManage.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'hatManage.district_id': function (newVal, oldVal) {
            this.upazillaList = this.getUpazillaList(newVal)
            this.hatList = this.getHatList(newVal)
        },
        'hatManage.upazilla_id': function (newVal, oldVal) {
            // this.hatList = this.getHatList(newVal)
        },
        cottonMarketUserInfo: function () {
            this.setUserData()
        }
    },
    methods: {
        hattManageData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(agriMarketingServiceBaseUrl, `${hatManageUpdate}/${this.id}`, this.hatManage)
            } else {
                result = await RestApi.postData(agriMarketingServiceBaseUrl, hatManageStore, this.hatManage)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getRelatinalData (data) {
            const orgList = this.$store.state.commonObj.organizationProfileList
            const regionList = this.$store.state.agriMarketing.commonObj.regionList
            const zoneList = this.$store.state.agriMarketing.commonObj.zoneList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const unitList = this.$store.state.agriMarketing.commonObj.unitList

            const listData = data.map(item => {
                const orgObject = orgList.find(org => org.value === item.org_id)
                const regionObject = regionList.find(region => region.value === item.region_id)
                const zoneObject = zoneList.find(zone => zone.value === item.zone_id)
                const districtObject = districtList.find(district => district.value === item.district_id)
                const upazilaObject = upazilaList.find(upazila => upazila.value === item.upazilla_id)
                const unitObject = unitList.find(unit => unit.value === item.unit_id)

                const orgData = { org_name: orgObject.text, org_name_bn: orgObject.text_bn }
                const regionData = { region_name: regionObject.text, region_name_bn: regionObject.text_bn }
                const zoneData = { zone_name: zoneObject.text, zone_name_bn: zoneObject.text_bn }
                const districtData = { district_name: districtObject.text, district_name_bn: districtObject.text_bn }
                const upazilaData = { upazila_name: upazilaObject.text, upazila_name_bn: upazilaObject.text_bn }
                const unitData = { unit_name: unitObject.text, unit_name_bn: unitObject.text_bn }

                return Object.assign({}, item, orgData, regionData, zoneData, districtData, upazilaData, unitData)
            })
            return listData
        },
        getZoneList (regionId) {
            // const zoneList = this.$store.state.agriMarketing.commonObj.zoneList
            // if (regionId) {
            //     return zoneList.filter(item => item.region_id === regionId && item.status === 1)
            // }
            const zoneList = this.cottonMarketUserInfo.zoneList.filter(item => item.status === 1 && item.region_id === regionId)
            return zoneList
        },
        getUnitList (zoneId) {
            // const unitList = this.$store.state.agriMarketing.commonObj.unitList
            // if (zoneId) {
            //     return unitList.filter(item => item.zone_id === zoneId && item.status === 1)
            // }
            const unitList = this.cottonMarketUserInfo.unitList.filter(item => item.status === 1 && item.zone_id === zoneId)
            return unitList
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazillaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getHatList (distrcitId = null) {
            const hatList = this.$store.state.agriMarketing.commonObj.hatList
            if (distrcitId) {
                return hatList.filter(item => item.district_id === distrcitId && item.status === 1)
            }
            return hatList
        },
        getApplicantInfo (applicantId) {
            const singeItem = this.applicantList.find(item => item.id === applicantId)
            this.hatManage.district_name = singeItem.district_name
            this.hatManage.district_name_bn = singeItem.district_name_bn
            this.hatManage.upazila_name = singeItem.upazila_name
            this.hatManage.upazila_name_bn = singeItem.upazila_name_bn
            this.hatManage.zone_name = singeItem.zone_name
            this.hatManage.zone_name_bn = singeItem.zone_name_bn
            this.hatManage.region_name = singeItem.region_name
            this.hatManage.region_name_bn = singeItem.region_name_bn
            this.hatManage.unit_name = singeItem.unit_name
            this.hatManage.unit_name_bn = singeItem.unit_name_bn
            this.hatManage.growers_name = singeItem.name
            this.hatManage.growers_name_bn = singeItem.name_bn
        },
        setUserData () {
            if (this.cottonMarketUserInfo.regionList.length === 1) {
                this.hatManage.region_id = this.cottonMarketUserInfo.regionList[0].value
            }
            if (this.cottonMarketUserInfo.zoneList.length === 1) {
                this.hatManage.zone_id = this.cottonMarketUserInfo.zoneList[0].value
            }
            if (this.cottonMarketUserInfo.unitList.length === 1) {
                this.hatManage.unit_id = this.cottonMarketUserInfo.unitList[0].value
            }
        },
        setHattInfo (id) {
            const obj = this.cottonMarketUserInfo.unitList.find(item => item.value === parseInt(id))
            const districtObj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(obj.district_id))
            this.hatManage = Object.assign({}, this.hatManage, {
                division_id: districtObj.division_id,
                district_id: obj.district_id,
                upazilla_id: obj.upazilla_id
            })
        }
    }
}
</script>
